import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import LogoIcon from 'icons/footer-logo.svg'

export const FooterWrapper = styled.footer`
  background: var(--black);
  padding: ${calculateResponsiveSize(23)} ${calculateResponsiveSize(60)};
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(23)};
  color: var(--white);
  align-items: center;
  width: 100%;

  ${mediaMax('mobile')} {
    padding: 30px 0 72px;
    gap: 30px;
  }
`
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 30px;
  }
`

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(316)};
  height: ${calculateResponsiveSize(48)};
  background: url(${LogoIcon}) no-repeat;
  background-size: contain;
  ${mediaMax('mobile')} {
    width: 259px;
    height: 40px;
  }
`
export const Address = styled.address`
  font-family: 'Avenir Next 600';
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(18)};
  letter-spacing: ${calculateResponsiveSize(2)};
  text-transform: uppercase;
  font-style: normal;
  width: ${calculateResponsiveSize(410)};
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 2px;
    width: 100%;
    text-align: center;
    order: 2;
  }
`

export const LinkItem = styled((props) => <Link {...props} />)`
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Avenir Next';
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(18)};
  letter-spacing: ${calculateResponsiveSize(2)};

  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 2px;
  }
`

export const Copyright = styled.div`
  font-family: 'Avenir Next';
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(16)};
  letter-spacing: 0em;
  width: ${calculateResponsiveSize(410)};
  text-align: right;
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0em;
    width: 100%;
    text-align: center;
    order: 3;
    margin-top: -30px;
  }
`
export const Links = styled.nav`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: ${calculateResponsiveSize(36)};
  ${mediaMax('mobile')} {
    gap: 12px;
    flex-direction: column;
    align-items: center;
    &.header {
      position: absolute;
      bottom: 36px;
      width: 100%;
      ${LinkItem} {
        color: var(--black);
      }
    }
  }
`
