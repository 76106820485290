import React from 'react'
import Button from '../ui/button'
import Modal from '../ui/modal'
import ContactModal from '../contact/contact-modal'
import useWindowSize from '../../helpers/useWindowSize'

const ContactButton = ({
  className,
  onOpenClick = () => {},
  onCloseClick = () => {},
  open = false,
}) => {
  //const [show, setShow] = React.useState(false)
  const { isMobile } = useWindowSize()

  return (
    <>
      <Button
        title={'Contact'}
        to="/"
        as="button"
        onClick={() => {
          // setShow(true)
          onOpenClick()
        }}
        className={className}
      />
      <Modal open={open} withHeader={isMobile}>
        <ContactModal
          onClose={() => {
            // setShow(false)
            onCloseClick()
          }}
        />
      </Modal>
    </>
  )
}
export default ContactButton
