import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Links, LinkItem } from './index.styled'
//import Legal from '../legal'
//import Policy from '../policy'
//import useWindowSize from '../../helpers/useWindowSize'

const FooterLinks = ({ className = '', onClose }) => {
  const {
    dataJson: {
      footerInfo: { links },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          links {
            title
            link
            external
          }
        }
      }
    }
  `)
  //const { isMobile } = useWindowSize()

  return (
    <Links className={className}>
      {links.map((l, i) => {
        return l.external ? (
          <LinkItem
            key={`links-${i}`}
            to={l.link}
            onClick={onClose}
            target={'_blank'}
            as="a"
          >
            {l.title}
          </LinkItem>
        ) : (
          <LinkItem key={`links-${i}`} to={l.link} onClick={onClose}>
            {l.title}
          </LinkItem>
        )
      })}
    </Links>
  )
}

export default FooterLinks
