import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Content,
  Price,
  Desc,
  Users,
  User,
  Name,
  Email,
  Phone,
  Fio,
} from './index.styled'

const ContactInfo = () => {
  const {
    dataJson: {
      footerInfo: { price, desc, users, press, pricetext },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          price
          pricetext
          users {
            name
            email
            phone
          }
          desc
          press {
            name
            email
            title
          }
        }
      }
    }
  `)
  return (
    <Content>
      <Price>
        {pricetext} <span>$</span>
        {price}
      </Price>
      <Desc>{desc}</Desc>
      <Users>
        {users.map((user) => (
          <User key={`contactc-user-${user.name}`}>
            <Name>{user.name}</Name>
            <Email href={`mailto:${press.email}`}>{user.email}</Email>
            <Phone href={`tel:${press.phone}`}>{user.phone}</Phone>
          </User>
        ))}
        <User>
          <Name>{press.title}</Name>
          <Fio>{press.name}</Fio>
          <Email href={`mailto:${press.email}`} className={'noUnderline'}>
            {press.email}
          </Email>
        </User>
      </Users>
    </Content>
  )
}

export default ContactInfo
