import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import LogoIcon from 'icons/footer-logo.svg'

export const Section = styled.section`
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - ${calculateResponsiveSize(130)});
  ${mediaMax('mobile')} {
    height: auto;
  }
`
export const Content = styled.div`
  width: ${calculateResponsiveSize(1180)};
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(30)};
  color: var(--black);
  ${mediaMax('mobile')} {
    width: 100%;
    padding: 60px 20px;
    gap: 38px;
  }
`
export const Price = styled.div`
  font-family: 'Armani';
  font-size: ${calculateResponsiveSize(36)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(36)};
  letter-spacing: 0em;
  text-transform: uppercase;
  padding-bottom: ${calculateResponsiveSize(30)};
  border-bottom: 1px solid var(--black);
  & span {
    font-family: 'Didot';
  }
  ${mediaMax('mobile')} {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 30px;
  }
`
export const Desc = styled.div`
  font-family: 'Avenir Next 600';
  font-size: ${calculateResponsiveSize(16)};
  font-weight: 600;
  line-height: ${calculateResponsiveSize(18)};
  letter-spacing: ${calculateResponsiveSize(2)};
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    font-size: 16px;
    line-height: 20px;
  }
`
export const Users = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 42px;
  }
`
export const User = styled.div``
export const Name = styled.div`
  font-family: 'Avenir Next 600';
  font-size: ${calculateResponsiveSize(18)};
  line-height: ${calculateResponsiveSize(30)};
  letter-spacing: 0em;
  margin-bottom: ${calculateResponsiveSize(12)};
  ${mediaMax('mobile')} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 9px;
  }
`
export const Text = css`
  font-family: Avenir Next;
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(30)};
  letter-spacing: 0em;
  color: var(--black);
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 20px;
  }
`
export const Email = styled((props) => <a {...props} />)`
  display: flex;
  color: var(--black);
  ${Text}
  &:hover {
    text-decoration: none;
  }
  &.noUnderline {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
export const Phone = styled((props) => <a {...props} />)`
  text-decoration: none;
  display: flex;
  color: var(--black);
  ${Text}
`
export const Fio = styled.div`
  ${Text}
`

export const WrapPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: dotted;
  right: 0;
  height: 100%;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`
