import React from 'react'
import FooterLinks from '../../footer/footer-links'

import {
  Wrap,
  NavigationLinksWrapper,
  NavigationLi,
  NavigationItem,
} from './index.styled'

const MobileMenu = ({ className, content, onItemClick = () => {} }) => {
  return (
    <Wrap className={className}>
      <NavigationLinksWrapper>
        {content.map((item, index) => (
          <React.Fragment key={`header-item-${index}`}>
            <NavigationLi onClick={onItemClick}>
              <NavigationItem to={item.url}>{item.text}</NavigationItem>
            </NavigationLi>
          </React.Fragment>
        ))}
      </NavigationLinksWrapper>
      <FooterLinks className={'header'} onClose={onItemClick} />
    </Wrap>
  )
}
export default MobileMenu
