import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import HeaderLogo from 'icons/intro-logo.svg'

export const FirstPartWrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${calculateResponsiveSize(38)};
  ${mediaMax('mobile')} {
    gap: 17.7px;
  }
`
export const FirstPart = styled.div`
  height: 100vh;
  transition: 1s;
  background: var(--black);
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
export const SecondPartWrap = styled.div`
  position: relative;
  top: -${calculateResponsiveSize(228.5 / 2)};
  display: flex;
  height: ${calculateResponsiveSize(228.5)};
  ${mediaMax('mobile')} {
    height: 106px;
    top: -53px;
  }
`

export const SecondPart = styled.div`
  transition: 1s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoWrap = styled.div`
  width: ${calculateResponsiveSize(200)};

  height: ${calculateResponsiveSize(228.5)};
  overflow: hidden;
  transition: 1s;
  transition-delay: 0.3s;
  transition-timing-function: linear


  animation: 4s ease 0s normal forwards 1 fadein;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax('mobile')} {
    width: 92.68px;
    height: 106.2px;
    
  }
`
export const LogoTextWrap = styled.div`
  position: relative;
  top: ${calculateResponsiveSize(228.5 / 2)};

  width: ${calculateResponsiveSize(613)};
  transition: 1s;
  animation: 4s ease 0s normal forwards 1 fadein1;
  @keyframes fadein1 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax('mobile')} {
    width: 285px;
    top: 53px;
  }
`
export const LogoTextWrap2 = styled.div`
  position: relative;
  top: ${calculateResponsiveSize(228.5 / 2)};

  width: ${calculateResponsiveSize(411)};
  transition: 1s;
  animation: 4s ease 0s normal forwards 1 fadein2;
  @keyframes fadein2 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax('mobile')} {
    width: 190.45px;
    top: 53px;
  }
`
export const FirstSlide = styled.div`
  z-index: 1;
  position: relative;
  height: 100%;
  transition: 1s;
  transition-delay: 1.3s;

  &.hide {
    transform: translateX(-100%);

    ${FirstPart} {
      height: 0;
    }
    ${LogoWrap} {
      height: 0;
    }
  }
`

export const Container = styled.div`
  position: relative;
  z-index: 1001;
  height: 100vh;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
