import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby-link'

export const Wrap = styled.div`
  .cookies-banner {
    position: fixed;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    color: var(--black);
    height: ${calculateResponsiveSize(120)};
    padding: ${calculateResponsiveSize(36)};
    font-family: 'Avenir Next';
    font-size: ${calculateResponsiveSize(18)};
    line-height: ${calculateResponsiveSize(24)};
    letter-spacing: 0em;
    width: ${calculateResponsiveSize(1041)};
    left: calc(50% - ${calculateResponsiveSize(1041 / 2)});
    bottom: ${calculateResponsiveSize(36)};
    opacity: 0;
    transition: 0.5s;
  }
  &.show {
    .cookies-banner {
      opacity: 1;
    }
  }
  .cookies-banner-accept-btn {
    font-family: 'Avenir Next';
    font-size: ${calculateResponsiveSize(18)};
    line-height: ${calculateResponsiveSize(24)};
    letter-spacing: 0.1em;
    text-align: center;

    background: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    background: var(--black);
    color: var(--white);
    transition: 0.5s;
    width: ${calculateResponsiveSize(245)};
    padding: ${calculateResponsiveSize(12)};
    &:hover {
      background: var(--gray);
    }
  }

  ${mediaMax('mobile')} {
    .cookies-banner {
      height: 53px;
      padding: 0px 20px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      left: 0;
      bottom: 0px;
    }
    .cookies-banner-accept-btn {
      height: 30px;
      width: auto;
      padding: 1px 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
    }
    .cookies-banner-btns {
      margin-left: auto;
    }
  }
`
export const Content = styled.div``

export const LinkItem = styled((props) => <Link {...props} />)`
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Avenir Next';
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(18)};
  letter-spacing: ${calculateResponsiveSize(2)};
  background: transparent;
  border: none;
  cursor: pointer;
  &.cookie {
    text-transform: capitalize;
    font-size: ${calculateResponsiveSize(18)};
    line-height: ${calculateResponsiveSize(24)};
    letter-spacing: 0em;
    text-decoration: underline;
    color: var(--black);
  }
  ${mediaMax('mobile')} {
    &.cookie {
      font-size: 14px;
      line-height: 20px;
    }
  }
`
