import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FooterWrapper, Bottom, Copyright, Address, Logo } from './index.styled'
import FooterLinks from './footer-links'

const Footer = ({ className, onClose = () => {} }) => {
  const {
    dataJson: {
      footerInfo: { copyright, address },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          address
          copyright
        }
      }
    }
  `)
  return (
    <FooterWrapper className={className}>
      <Logo to="/" onClick={onClose} />
      <Bottom>
        <Address>{address}</Address>
        <FooterLinks onClose={onClose} />
        <Copyright>{copyright}</Copyright>
      </Bottom>
    </FooterWrapper>
  )
}
export default Footer
