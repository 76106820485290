import React, { useRef, useState } from 'react'
import ContactButton from '../../header/contact-button'

import videoUrl from 'images/760-video.mp4'

import { Video, Logo, BgImg, Header, Container, Arrow } from './index.styled'
import { useEffect } from 'react'

const HomeIntroAnimation2 = ({
  setIsFirstLoad = () => {},
  setShowContact,
  showContact,
}) => {
  const ref = useRef()

  const onScroll = () => {
    ref.current.classList.add('hide')
    document.body.style.overflow = 'initial'
    setTimeout(() => {
      setIsFirstLoad(false)
    }, 2000)
  }

  useEffect(() => {
    // document.body.style.overflow = 'hidden'

    setTimeout(() => {
      ref?.current?.classList?.add('show')
      ref?.current?.addEventListener('scroll', onScroll)
    }, 4000)

    return () => {
      ref?.current?.removeEventListener('scroll', onScroll, false)
      document.body.style.overflow = 'initial'
      setIsFirstLoad(false)
    }
  }, [])

  return (
    <Container>
      <BgImg className={'intro-img'} ref={ref}>
        <Header>
          <Logo />
          <ContactButton
            className="intro"
            open={showContact}
            onOpenClick={() => {
              setShowContact(true)
            }}
            onCloseClick={() => {
              setShowContact(false)
            }}
          />
        </Header>
        <Video autoPlay muted loop playsInline>
          <source src={videoUrl} type="video/mp4" />
        </Video>

        <Arrow />
      </BgImg>
    </Container>
  )
}

export default HomeIntroAnimation2
