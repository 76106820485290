import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import useWindowSize from 'helpers/useWindowSize'
import Header from 'components/header'
import Footer from 'components/footer'
import HomeIntroAnimation from '../ui/home-intro-animation'
import HomeIntroAnimation2 from '../ui/home-intro-animation-2'
import CookiesBanner from '../ui/cookies-banner'
import { Helmet } from 'react-helmet'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/globalStyles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)
  const isHomePage = location.pathname === '/'
  const { isMobile } = useWindowSize()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isFirstLoad2, setIsFirstLoad2] = useState(true)
  let scrollerSmoother

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      scrollerSmoother = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
      })
      //pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children])

  useEffect(() => {
    if (scrollerSmoother && location.hash) {
      gsap.to(scrollerSmoother, {
        scrollTop:
          document.querySelector(`${location.hash}-section`)?.offsetTop + 1,
        duration: 0,
      })
    }
  }, [scrollerSmoother, location.hash, isMobile])

  const [showContact, setShowContact] = useState(false)

  const href = location.href + 'images/opengraph.png'

  return (
    <>
      <div className="home-wrapper" ref={refWrapper}>
        {isHomePage && isFirstLoad && (
          <HomeIntroAnimation setIsFirstLoad={setIsFirstLoad} />
        )}
        <CookiesBanner />
        {isHomePage && isFirstLoad2 && !isMobile && (
          <HomeIntroAnimation2
            setIsFirstLoad={setIsFirstLoad2}
            showContact={showContact}
            setShowContact={setShowContact}
          />
        )}

        <Header
          className={layout}
          showContact={showContact}
          setShowContact={setShowContact}
        />
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <Footer onCose={() => setShowContact()} />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
