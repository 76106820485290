import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import HeaderLogo from 'icons/header-logo.svg'

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${calculateResponsiveSize(0)} ${calculateResponsiveSize(36)};
  ${mediaMax('mobile')} {
    padding: 0 20px;
  }
`
export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(43)};
  height: ${calculateResponsiveSize(48)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: white;
  transition: 1s;
  ${mediaMax('mobile')} {
    width: 42.18px;
    height: 48px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
  }
`
export const NavigationItem = styled((props) => <Link {...props} />)`
  font-family: 'Avenir Next 600';
  font-size: ${calculateResponsiveSize(16)};
  line-height: 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: 0;
  background: none;
  cursor: pointer;
  color: var(--gray);
  transition: 0.5s;
  padding: 5px 0;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--white);
  }
`
export const NavigationLi = styled.li`
  height: ${calculateResponsiveSize(70)};
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const NavigationLinksWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(96)};
`
export const MobileHeaderButton = styled.button`
  & {
    background: transparent;
    width: 34px;
    height: 34px;
    background: transprent;
    border: 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 11;
    position: relative;
  }
  & span {
    display: block;
    position: absolute;

    height: 2px;
    width: 100%;
    background: var(--white);
    border-radius: 0px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.7s ease-in-out;
  }
  span:nth-child(1) {
    top: 12px;
  }
  span:nth-child(2) {
    top: 22px;
  }

  &.open span {
    background: var(--black);
  }

  &.open span:nth-child(1) {
    top: 17px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(2) {
    top: 17px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`
export const Wrap = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--black);
  transition: all 2s;
  z-index: 10;
  height: ${calculateResponsiveSize(72)};
  border-bottom: 1px solid var(--white);
  &.without-header {
    display: none;
  }
  ${mediaMax('mobile')} {
    &.without-header {
      display: flex;
    }
    height: 72px;
    &.open {
      ${Logo} {
        background: var(--black);
      }
    }
  }
`
