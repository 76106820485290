import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const ModalContentWrap = styled.div`
  width: 100%;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`
