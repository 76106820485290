import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  border: 1px solid var(--white);
  font-family: 'Avenir Next 600';
  font-size: ${calculateResponsiveSize(14)};
  line-height: 130%;
  letter-spacing: 0.1em;
  text-align: center;
  background: var(--black);
  color: var(--white);

  text-transform: uppercase;
  padding: ${calculateResponsiveSize(7)} ${calculateResponsiveSize(22)};
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    background: var(--white);
    color: var(--black);
  }
  &.white {
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
    &:hover {
      background: var(--black);
      color: var(--white);
    }
  }
  &.intro {
    background: transparent;
    position: absolute;
    right: ${calculateResponsiveSize(36)};
    top: ${calculateResponsiveSize(40)};
    &:hover {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--white);
    }
  }
  &.mobile {
    z-index: 11;
    background: transparent;
  }
  &.openMobMenu {
    color: var(--black);
    border: 1px solid var(--black);
  }

  ${mediaMax('mobile')} {
    padding: 6px 12px;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.1em;
    &:hover {
      background: var(--black);
      color: var(--white);
    }
  }
`
