import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
//import Button from '../ui/button'
import ContactButton from './contact-button'
import MobileMenu from './mobile-menu'

import {
  Wrap,
  Logo,
  Navigation,
  NavigationLinksWrapper,
  NavigationLi,
  NavigationItem,
  MobileHeaderButton,
} from './index.styled'
import useWindowSize from '../../helpers/useWindowSize'

const Header = ({ className, setShowContact, showContact }) => {
  const { isMobile } = useWindowSize()
  // const location = useLocation()
  const {
    menuContent: { content },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          text
          url
        }
      }
    }
  `)
  const [openMobMenu, setOpenMobMenu] = useState(false)

  return (
    <Wrap
      id={/* isMobile ? 'headerMob' :  */ 'header'}
      className={[className, openMobMenu ? 'open' : ''].join(' ')}
    >
      <Navigation>
        {isMobile ? (
          <>
            <MobileHeaderButton
              onClick={() => setOpenMobMenu(!openMobMenu)}
              className={[openMobMenu ? 'open ' : '']}
            >
              <span></span>
              <span></span>
            </MobileHeaderButton>
            <MobileMenu
              className={openMobMenu ? 'open' : ''}
              content={content}
              onItemClick={() => {
                setOpenMobMenu(false)
                setShowContact(false)
              }}
            />
            <Logo to="/" />
            <ContactButton
              className={openMobMenu ? 'mobile openMobMenu' : 'mobile'}
              open={showContact}
              onOpenClick={() => {
                setShowContact(true)
              }}
              onCloseClick={() => {
                setShowContact(false)
              }}
            />
          </>
        ) : (
          <>
            <Logo to="/" />
            <NavigationLinksWrapper>
              {content.map((item, index) => (
                <React.Fragment key={`header-item-${index}`}>
                  <NavigationLi>
                    <NavigationItem to={item.url}>{item.text}</NavigationItem>
                  </NavigationLi>
                </React.Fragment>
              ))}
            </NavigationLinksWrapper>
            <ContactButton
              open={showContact}
              onOpenClick={() => {
                setShowContact(true)
              }}
              onCloseClick={() => {
                setShowContact(false)
              }}
            />
          </>
        )}
      </Navigation>
    </Wrap>
  )
}
export default Header
