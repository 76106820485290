import React from 'react'
import CookieConsent from 'react-cookie-consent'
import useWindowSize from '../../../helpers/useWindowSize'
import { LinkItem, Content, Wrap } from './index.styled'

const CookiesBanner = () => {
  const ref = React.useRef()
  const { isMobile } = useWindowSize()

  React.useEffect(() => {
    setTimeout(() => {
      ref?.current?.classList?.add('show')
    }, 6000)
  }, [])

  return (
    <Wrap ref={ref}>
      <CookieConsent
        location={'none'}
        buttonText={isMobile ? 'Ok' : 'Accept'}
        disableStyles={true}
        containerClasses="cookies-banner"
        buttonClasses="cookies-banner-accept-btn"
        disableButtonStyles={true}
        buttonWrapperClasses={'cookies-banner-btns'}
        contentClasses={'cookies-banner-content'}
        expires={150}
        //debug={true}
      >
        <Content>
          {isMobile ? (
            <>
              This website uses{' '}
              <LinkItem className={'cookie'} to={'/policy'}>
                Cookies.
              </LinkItem>
            </>
          ) : (
            <>
              'We use cookies to improve your experience. By using this website
              you agree to our{' '}
              <LinkItem className={'cookie'} to={'/policy'}>
                Cookie Policy.
              </LinkItem>
            </>
          )}
        </Content>
      </CookieConsent>
    </Wrap>
  )
}
export default CookiesBanner
