import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import HeaderLogo from 'icons/intro-logo.svg'
import ArrowIcon from 'icons/leftArrow.svg'

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${mediaMax('mobile')} {
    #home > & {
      height: 60svh;
    }
  }
`

export const Header = styled.div`
  transform: translateY(-100%);
  transition: 1s;
  transition-delay: 1s;

  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${calculateResponsiveSize(48)};
  align-items: center;
  z-index: 1;
`
export const Logo = styled.div`
  width: ${calculateResponsiveSize(246)};
  height: ${calculateResponsiveSize(144)};
  background: url(${HeaderLogo}) no-repeat;
  background-size: contain;
`

export const Arrow = styled.div`
  width: ${calculateResponsiveSize(20)};
  height: ${calculateResponsiveSize(48)};
  background: url(${ArrowIcon}) no-repeat;
  background-size: contain;

  position: absolute;
  z-index: 1;
  bottom: -${calculateResponsiveSize(36)};
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);

  transition: 1s;
  transition-delay: 1s;

  animation: bounce 1s ease infinite alternate;

  @keyframes bounce {
    0% {
      transform: translate(-50%, ${calculateResponsiveSize(-10)}) rotate(-90deg);
    }
    100% {
      transform: translate(-50%, ${calculateResponsiveSize(10)}) rotate(-90deg);
    }
  }
`
export const Container = styled.div`
  position: relative;
  z-index: 105;
  height: 100vh;
  width: 100%;
`
export const BgImg = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  transition: 1s;
  overflow: auto;

  &.hide {
    transform: translateY(-100%);
  }
  &.show {
    ${Header} {
      transform: translateY(0);
    }
    ${Arrow} {
      bottom: ${calculateResponsiveSize(36)};
    }
  }
`
