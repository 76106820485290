import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  transition: all 1s;
  z-index: 10;
  height: 0;
  overflow: hidden;
  &.open {
    height: 100vh;
  }
`
export const NavigationLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
`
export const NavigationItem = styled((props) => <Link {...props} />)`
  font-family: 'Armani';
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--black);
  text-decoration: none;
  white-space: nowrap;
`
export const NavigationLi = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
`
