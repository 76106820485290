import React from 'react'
import PageWrap from '../ui/page-wrap'
import Footer from '../footer'
import ContactInfo from './contact-info'
import { WrapPopup } from './index.styled'

const ContactModal = ({ onClose }) => {
  return (
    <WrapPopup>
      <PageWrap onClose={onClose} className={'contact'}>
        <ContactInfo />
        <Footer onClose={onClose} />
      </PageWrap>
    </WrapPopup>
  )
}

export default ContactModal
