import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(246)};
  height: ${calculateResponsiveSize(144)};
  background: url(${HeaderLogo}) no-repeat;
  background-size: contain;
  flex: 0 0 auto;
`
export const BtnWrap = styled.div`
  display: flex;
  position: absolute;
  right: ${calculateResponsiveSize(36)};
  top: ${calculateResponsiveSize(36)};
`
export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  align-items: center;
  padding-top: ${calculateResponsiveSize(48)};
  height: calc(100vh - ${calculateResponsiveSize(135)});
  color: var(--black);
  &.contact {
    height: calc(100vh);
  }
  ${mediaMax('mobile')} {
    min-height: calc(100vh - 310px);
    height: auto;
    padding-top: 122px;
    ${Logo} {
      display: none;
    }
    &.contact {
      min-height: calc(100vh - 310px);
      height: 100%;
      padding-top: 72px;
      overflow: auto;
    }
  }
`
