import React, { useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import useWindowSize from '../../../helpers/useWindowSize'

import {
  FirstSlide,
  FirstPart,
  FirstPartWrap,
  SecondPartWrap,
  SecondPart,
  Container,
  LogoWrap,
  LogoTextWrap,
  LogoTextWrap2,
} from './index.styled'
import { useEffect } from 'react'

const HomeIntroAnimation = ({ setIsFirstLoad = () => {} }) => {
  const { isMobile } = useWindowSize()
  const refFirst = useRef()

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    setTimeout(() => {
      refFirst?.current?.classList.add('hide')
    }, 4000)

    setTimeout(() => {
      setIsFirstLoad(false)
    }, 5300)
  }, [])

  return (
    <Container>
      <FirstSlide ref={refFirst}>
        <FirstPart>
          <FirstPartWrap>
            <LogoTextWrap>
              <StaticImage alt="" src={'../../../images/logo-main-text.svg'} />
            </LogoTextWrap>
            <LogoTextWrap2>
              <StaticImage alt="" src={'../../../images/logo-main-text2.svg'} />
            </LogoTextWrap2>
          </FirstPartWrap>
        </FirstPart>
        <SecondPart>
          <SecondPartWrap>
            <LogoWrap>
              <StaticImage alt="" src={'../../../images/logo-main.svg'} />
            </LogoWrap>
          </SecondPartWrap>
        </SecondPart>
      </FirstSlide>
    </Container>
  )
}

export default HomeIntroAnimation
