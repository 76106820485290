import React from 'react'
import Button from '../button'
import { Wrap, Logo, BtnWrap } from './index.styled'

const PageWrap = ({ children, onClose, className }) => {
  return (
    <Wrap className={className}>
      {onClose && (
        <BtnWrap>
          <Button
            title={'Back'}
            as="button"
            className={'white'}
            onClick={onClose}
          />
        </BtnWrap>
      )}
      <Logo to="/" onClick={onClose} />
      {children}
    </Wrap>
  )
}
export default PageWrap
